// src/App.js
import { useState } from "react";
import Auth from "./components/Auth";
import WriteLoveLetter from "./components/WriteLoveLetter";
import LoveLetters from "./components/LoveLetters";
import "./App.css";

function App() {
  const [user, setUser] = useState(null);

  return (
    <div className="app-container">
      {/* Adding floating hearts */}
      <div className="heart"></div>
      <div className="heart"></div>
      <div className="heart"></div>
      <div className="heart"></div>

      {!user ? (
        <Auth setUser={setUser} />
      ) : (
        <div className="user-section">
          <h1 className="welcome-message">Welcome, Luvr &lt;3</h1>
          <div className="logout-button-container">
            <button className="logout-button" onClick={() => setUser(null)}>
              Log Out
            </button>
          </div>

          <WriteLoveLetter />
          <LoveLetters />
        </div>
      )}
    </div>
  );
}

export default App;

import { useState, useEffect } from "react";
import { db, auth } from "../firebase";
import { collection, query, where, getDocs, updateDoc, doc, Timestamp } from "firebase/firestore";  // Import Firestore
import "./LoveLetters.css";

function LoveLetters() {
  const [letters, setLetters] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getLoveLetters = async () => {
    const q = query(
      collection(db, "loveLetters"),
      where("toEmail", "==", auth.currentUser.email)
    );
    const querySnapshot = await getDocs(q);
    const fetchedLetters = [];
    querySnapshot.forEach((doc) => {
      fetchedLetters.push({ id: doc.id, ...doc.data() });
    });
    setLetters(fetchedLetters.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)); // Reverse to show newest letter first
  };

  const markAsRead = async (letterId) => {
    const letterRef = doc(db, "loveLetters", letterId);
    await updateDoc(letterRef, {
      "readReceipt.isRead": true,
      "readReceipt.readAt": Timestamp.now(),  // Store the timestamp of when the letter was read
    });
  };  

  useEffect(() => {
    getLoveLetters();
  }, []);

  useEffect(() => {
    if (letters.length > 0 && !letters[currentIndex].readReceipt?.isRead) {
      markAsRead(letters[currentIndex].id);  // Mark letter as read when viewed
    }
  }, [letters, currentIndex]);  // Trigger this effect when letters or the currentIndex changes  

  const handlePageTurn = (direction) => {
    if (direction === "next" && currentIndex < letters.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (direction === "prev" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="book-container">
      <div className={`book ${currentIndex % 2 === 0 ? "flip-left" : "flip-right"}`}>
        <div className="page">
          {letters.length > 0 && currentIndex >= 0 && currentIndex < letters.length ? (
            <div className="page-content">
              <h4 className="page-date">
                {new Date(letters[currentIndex].createdAt?.seconds * 1000).toLocaleString()}
              </h4>
              <h4 className="page-from">From: {letters[currentIndex].fromEmail}</h4>
              <p className="page-text">
                {letters[currentIndex].content.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
            </div>
          ) : (
            <p className="no-letters">No love letters yet!</p>
          )}
        </div>
        <div className="page-navigation">
          <button
            onClick={() => handlePageTurn("prev")}
            disabled={currentIndex === 0}
            className="nav-button"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageTurn("next")}
            disabled={currentIndex === letters.length - 1}
            className="nav-button"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoveLetters;

import { useState } from "react";
import { db, auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import "./WriteLoveLetter.css";

function WriteLoveLetter() {
  const [toEmail, setToEmail] = useState("");
  const [content, setContent] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const writeLoveLetter = async () => {
    if (!validateEmail(toEmail)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (content.trim() === "") {
      alert("Please write something in the love letter.");
      return;
    }

    try {
      await addDoc(collection(db, "loveLetters"), {
        fromEmail: auth.currentUser.email,
        toEmail,
        content,
        createdAt: new Date(),
        readReceipt: { isRead: false, readAt: null },  // Initialize readReceipt
      });
      setToEmail("");
      setContent("");
      alert("Love letter sent!");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  return (
    <div className="write-letter-container">
      <input
        type="email"
        value={toEmail}
        onChange={(e) => setToEmail(e.target.value)}
        placeholder="Partner's Email"
        className="write-input"
      />
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Write your love letter..."
        className="write-textarea"
      />
      <button onClick={writeLoveLetter} className="write-button">Send Letter</button>
    </div>
  );
}

export default WriteLoveLetter;
